// Safari Browser Control -------------------------------------------
let isSafari = navigator.vendor.match(/apple/i) &&
    !navigator.userAgent.match(/crios/i) &&
    !navigator.userAgent.match(/fxios/i) &&
    !navigator.userAgent.match(/Opera|OPT\//)
if (isSafari) body.classList.add('is-safari')

// FancyBox Settings ------------------------------------------------
Fancybox.defaults.placeFocusBack = 0;
Fancybox.defaults.autoFocus = false;
Fancybox.defaults.trapFocus = false;
Fancybox.defaults.dragToClose = false;

Fancybox.bind("[data-fancybox]", {
    infinite: false,
    Toolbar: { display: [ "download", "close" ], },
});

// Smart Menu -------------------------------------------------------
let currentScrollTop = $(window).scrollTop();
if (currentScrollTop >= 50) { header.classList.add('hidden'); header.classList.add('is-fixed'); }
else { header.classList.remove('hidden'); header.classList.remove('is-fixed'); }

let lastScrollTop = 0;
let stickyObject = $('.sticky');
$(window).scroll(function () {
    var scrollTop = $(this).scrollTop();
    if (scrollTop > lastScrollTop) {
        stickyObject.css('top', '45px');
        if (scrollTop > 50) { header.classList.add('hidden'); header.classList.add('is-fixed'); }
    } else {
        if (!header.classList.contains('no-scroll')) stickyObject.css('top', header.clientHeight + 45);

        if (scrollTop > 50) { header.classList.remove('hidden'); header.classList.add('is-fixed'); }
        else { header.classList.remove('hidden'); header.classList.remove('is-fixed'); }
    }
    lastScrollTop = scrollTop;
});

// Tel Input Only Number Fix ----------------------------------------
function setInputFilter(textbox, inputFilter) {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
        textbox.addEventListener(event, function () {
            if (inputFilter(this.value)) {
                this.oldValue = this.value
                this.oldSelectionStart = this.selectionStart
                this.oldSelectionEnd = this.selectionEnd
            } else if (this.hasOwnProperty("oldValue")) {
                this.value = this.oldValue
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd)
            } else {
                this.value = ""
            }
        })
    })
}

// Accordion --------------------------------------------------------
const slideDown = elem => { elem.style.height = `${elem.scrollHeight + 30}px`; }
const slideUp = elem => { elem.style.height = `0px`; }

let accordions = document.querySelectorAll('.accordion');
accordions.forEach((element, index) => {
    element.querySelector('.shown').addEventListener('click', () => {
        let classes = element.querySelector('.shown').parentNode.getAttribute('class')
        if(!classes.includes('active')) {
            element.classList.add('active')
            slideDown(element.querySelector('.hidden'));
        }
        else {
            element.classList.remove('active')
            slideUp(element.querySelector('.hidden'));
        }
    });

    let classes = element.querySelector('.shown').parentNode.getAttribute('class')
    if(classes.includes('active')) slideDown(element.querySelector('.hidden'));
});

// Find Middle Child ------------------------------------------------
window.findMiddleObject = function(arr, middleBeforeClass, middleAfterClass, middleClass) {
    if(arr.length % 2 == 0) {
        middleBefore = arr[Math.floor((arr.length - 1) / 2)];
        middleAfter = arr[Math.ceil((arr.length - 1) / 2)];

        middleBefore.classList.add(middleBeforeClass)
        middleAfter.classList.add(middleAfterClass)
    }
    else {
        middle = arr[Math.round((arr.length - 1) / 2)];
        middle.classList.add(middleClass)
    }
}

// Mobile Menu -----------------------------------------------------
/* $('.mobile-menu-trigger').click(function() {
    if( !$(this).hasClass('active') ) {
        $(this).addClass('active');
        $('body').css('overflow-y', 'hidden');
        $('.main-menu').show();
    }
    else {
        $('.menu-background').removeClass('active');
        $(this).removeClass('active');
        $('body').css('overflow-y', 'visible');
        $('.main-menu').hide();
    }
});

if( $(window).width() <= 1200 ) {
    $('.main-menu > li.has-child > span.trigger').remove();
    $('.main-menu > li.has-child > a').after('<span class="trigger"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M47.55,20H32.45A2.44,2.44,0,0,1,30,17.55V2.45A2.44,2.44,0,0,0,27.55,0h-5.1A2.44,2.44,0,0,0,20,2.45v15.1A2.44,2.44,0,0,1,17.55,20H2.45A2.44,2.44,0,0,0,0,22.45v5.1A2.44,2.44,0,0,0,2.45,30h15.1A2.44,2.44,0,0,1,20,32.45v15.1A2.44,2.44,0,0,0,22.45,50h5.1A2.44,2.44,0,0,0,30,47.55V32.45A2.44,2.44,0,0,1,32.45,30h15.1A2.44,2.44,0,0,0,50,27.55v-5.1A2.44,2.44,0,0,0,47.55,20Z"/></svg></span>')
}

$(document).on('click', '.main-menu > li.has-child span.trigger', function() {
    if( !$(this).hasClass('active') ) {
        $(this).addClass('active');
        $(this).next('.sub-menu').show();
    }
    else {
        $(this).removeClass('active');
        $(this).next('.sub-menu').hide();
    }
}); */

// Text Reveal Animation ---------------------------------------------
$('.text-reveal-animation').each(function() {
    if( $(window).width() > mobileLimit ) {
        document.fonts.ready.then(() => {
            let text = $(this).html();
            $(this).html('<span class="actual">' + text + '</span><span class="placeholder">' + text + '</span>')

            let placeholder = new SplitText($(this).find(".placeholder"), { type: "chars, words, lines", charsClass: 'split-chars', wordsClass: 'split-words', linesClass: 'split-lines' });
            let actual = new SplitText($(this).find(".actual"), { type: "chars, words, lines", charsClass: 'split-chars', wordsClass: 'split-words', linesClass: 'split-lines' });
            
            let textRevealAniTL = gsap.timeline({ 
                scrollTrigger: {
                    trigger: $(this),
                    start: 'top 90%',
                    end: 'bottom top',
                    once: true,
                },
            });
            
            textRevealAniTL.to(placeholder.chars, { duration: 0.25, rotateY: 90, translateZ: 0, opacity: 0, stagger: 0.015 })
            .to(actual.chars, { delay: 0.05, duration: 0.5, rotateY: 0, translateZ: 0, opacity: 1, stagger: 0.015 }, 0);
        });
    }
});

// Image Scroll Move Animation ------------------------------------------
$('.image-scroll-move').each(function() {
    let image = $(this).find('img');
    let wrapper = $(this);
    
    let loadedImage = document.createElement('img');
    loadedImage.src = image.attr('src');

    loadedImage.onload = function () { 
        image.height( image.height() * 1.25 );
        
        gsap.to(image, {
            scrollTrigger: {
                trigger: wrapper,
                start: 'top-=25% center',
                end: 'bottom+=25% center',
                scrub: 1.5,
            },
            y: -(image.height() * 0.2),
        });
    }
});

// Dynamic Height Change For Class --------------------------------------
if( $(window).width() > 768 ) {
    if( $('.height-dynamic-viewport').length ) $('.height-dynamic-viewport').height( $(window).height() );
    $(window).resize(function() { if( $('.height-dynamic-viewport').length ) $('.height-dynamic-viewport').height( $(window).height() ); });
} 
else {
    if( $('.height-dynamic-viewport').length ) $('.height-dynamic-viewport').height( $(window).height() - $('header').height() );
    $(window).resize(function() { if( $('.height-dynamic-viewport').length ) $('.height-dynamic-viewport').height( $(window).height() - $('header').height() ); });
}

if( $('.height-dynamic-viewport-offset-header').length ) $('.height-dynamic-viewport-offset-header').height( $(window).height() - $('header').height() );
$(window).resize(function() { if( $('.height-dynamic-viewport-offset-header').length ) $('.height-dynamic-viewport-offset-header').height( $(window).height() - $('header').height() ); });