// Global Variables -------------------------------------------------
global.body = document.querySelector('body');
global.header = document.querySelector('header');
global.footer = document.querySelector('footer');
global.main = document.querySelector('main');
global.windowWidth = window.innerWidth;
global.windowHeight = window.innerHeight;
global.mobileLimit = 1024;

// jQuery ----------------------------------------------------------- 
import jQuery from 'jquery'
window.jQuery = jQuery
window.$ = jQuery

// FancyBox ---------------------------------------------------------
import { Fancybox } from '@fancyapps/ui'
window.Fancybox = Fancybox

// PIXI -------------------------------------------------------------
import * as PIXI from 'pixi.js'
window.PIXI = PIXI;
PIXI.utils.skipHello();