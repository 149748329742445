// Swiper -----------------------------------------------------------
import Swiper, * as Carousel from 'swiper';
window.Swiper = Swiper;
window.Carousel = Carousel;

let servicesTextCarousel = new Swiper('.services-text-carousel', {
    speed: 1000,
    slidesPerView: 1,
    spaceBetween: 45,
    allowTouchMove: false,
});

let servicesImageCarousel = new Swiper('.services-image-carousel', {
    speed: 1000,
    slidesPerView: 1,
    spaceBetween: 45,
    modules: [Carousel.Autoplay, Carousel.Navigation, Carousel.EffectCreative],
    on: {
        beforeInit: function () {
            let numOfSlides = this.wrapperEl.querySelectorAll(".swiper-slide").length;
            $('.services-carousel-wrapper .container .services-image-carousel .carousel-nav .count .total').html(numOfSlides);
        },
        slideChange: function(){
            var currentSlide = this.realIndex + 1;
            $('.services-carousel-wrapper .container .services-image-carousel .carousel-nav .count .current').html(currentSlide);
        },
    },
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    effect: 'creative',
    creativeEffect: {
        transformEl: '.services-carousel-wrapper .container .services-image-carousel .swiper-wrapper .swiper-slide .image',
        prev: {
            opacity: 0,
            scale: 0.5,
            translate: ['-65%', '65%', 0],
        },
        next: {
            translate: ['115%', '40%', 0],
        },
    },
    navigation: {
        prevEl: '.services-image-prev',
        nextEl: '.services-image-next',
    }
});

servicesImageCarousel.on('slideChange', function() {
    servicesTextCarousel.slideTo(servicesImageCarousel.activeIndex, 1000);
});

let heroTextCarousel = new Swiper('.hero-content-carousel', {
    speed: 1000,
    slidesPerView: 1,
    spaceBetween: 45,
    allowTouchMove: false,
});

let heroImageCarousel = new Swiper('.hero-image-carousel', {
    speed: 1000,
    slidesPerView: 1,
    spaceBetween: 45,
    allowTouchMove: false,
    modules: [Carousel.EffectCreative],
    effect: 'creative',
    creativeEffect: {
        prev: {
            scale: 0.66,
            translate: ['71%', '31%', 0],
        },
        next: {
            scale: 0.66,
            translate: ['80%', '-35%', 0],
        },
    },
});


let heroThumbsCarousel = new Swiper('.hero-thumbs-carousel', {
    speed: 1000,
    slidesPerView: 1,
    spaceBetween: 45,
    direction: 'vertical',
    slideToClickedSlide: true,
    modules: [Carousel.Navigation, Carousel.Autoplay],
    autoplay: {
        delay: 7500,
        disableOnInteraction: false,
    },
    navigation: {
        prevEl: '.hero-thumbs-prev',
        nextEl: '.hero-thumbs-next',
    },
    on: {
        afterInit: function() {
            gsap.to('.hero .content .text-area .hero-thumbs-carousel .swiper-wrapper .swiper-slide:nth-child('+ (this.activeIndex + 1) +') .item .progress .fill', { duration: 7.5, ease: 'none', scaleX: 1 })
        },
        slideChange: function(){
            gsap.set('.hero .content .text-area .hero-thumbs-carousel .swiper-wrapper .swiper-slide .item .progress .fill', { scaleX: 0, overwrite: true, })
            gsap.to('.hero .content .text-area .hero-thumbs-carousel .swiper-wrapper .swiper-slide:nth-child('+ (this.activeIndex + 1) +') .item .progress .fill', { duration: 8.5, ease: 'none', scaleX: 1 })
        },
    },
});

heroThumbsCarousel.on('slideChange', function() {
    heroImageCarousel.slideTo(heroThumbsCarousel.activeIndex, 1000);
    heroTextCarousel.slideTo(heroThumbsCarousel.activeIndex, 1000);
});