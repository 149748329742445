// Menu Trigger
let menuTrigger = $('header .wrapper .menu-trigger');
let headerEl = $('header');
let mainEl = $('main');
let bodyEl = $('body');
let menuHasChild = $('.main-menu > li.has-child');
let menuHasChildSubMenu = $('.main-menu > li.has-child .sub-menu');
menuTrigger.click(function() {
    if( !$(this).hasClass('active') ) {
        $(this).addClass('active');
        headerEl.addClass('active');
        mainEl.addClass('active');
        bodyEl.css('overflow', 'hidden');
    }
    else {
        $(this).removeClass('active');
        headerEl.removeClass('active');
        mainEl.removeClass('active');
        headerEl.removeClass('sub-menu');
        menuHasChild.removeClass('active');
        menuHasChildSubMenu.stop(true, true).slideUp(450);
        bodyEl.css('overflow', 'visible');
    }
});

menuHasChild.click(function() {
    if( !$(this).hasClass('active') ) {
        headerEl.addClass('sub-menu');
        menuHasChild.removeClass('active');
        $(this).addClass('active');
        menuHasChildSubMenu.stop(true, true).slideUp(450);
        $(this).find('.sub-menu').stop(true, true).slideDown(450);
    }
    else {
        headerEl.removeClass('sub-menu');
        $(this).removeClass('active');
        $(this).find('.sub-menu').stop(true, true).slideUp(450);
    }
});

let contactFieldTexture = $('.contact-field .texture');
if( contactFieldTexture.length ) {
    let contactFieldPIXI;

    function initPixi() {
        contactFieldPIXI = new PIXI.Application({backgroundAlpha: 0, width: contactFieldTexture.innerWidth(), height: contactFieldTexture.innerHeight()});
        contactFieldTexture.append(contactFieldPIXI.view);
    
        let image = new PIXI.Sprite.from( contactFieldTexture.find('img').attr('src') );
        image.width = contactFieldTexture.innerWidth();
        image.height = contactFieldTexture.innerHeight();
        contactFieldPIXI.stage.addChild(image);
    
        let displacementSprite, displacementFilter;
        displacementSprite = new PIXI.Sprite.from('../assets/image/static/cloud.jpg');
        displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
        displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
        contactFieldPIXI.stage.addChild(displacementSprite);
        contactFieldPIXI.stage.filters = [displacementFilter];
    
        displacementSprite.scale.x = 0.75;
        displacementSprite.scale.y = 0.75;

        gsap.to(displacementSprite, {
            duration: 1,
            repeat: -1,
            ease: 'none',
            repeatRefresh: true,
            pixi: {
                x: '+=10',
                y: '+=10',
            }
        })
    }
    
    initPixi();
}

let productFeaturesTexture = $('.product-features .texture');
if( productFeaturesTexture.length ) {
    let productFeaturesPIXI;

    function initPixi() {
        productFeaturesPIXI = new PIXI.Application({backgroundAlpha: 0, width: productFeaturesTexture.innerWidth(), height: productFeaturesTexture.innerHeight()});
        productFeaturesTexture.append(productFeaturesPIXI.view);
    
        let image = new PIXI.Sprite.from( productFeaturesTexture.find('img').attr('src') );
        image.width = productFeaturesTexture.innerWidth();
        image.height = productFeaturesTexture.innerHeight();
        productFeaturesPIXI.stage.addChild(image);
    
        let displacementSprite, displacementFilter;
        displacementSprite = new PIXI.Sprite.from('../assets/image/static/cloud.jpg');
        displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
        displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
        productFeaturesPIXI.stage.addChild(displacementSprite);
        productFeaturesPIXI.stage.filters = [displacementFilter];
    
        displacementSprite.scale.x = 0.75;
        displacementSprite.scale.y = 1;

        gsap.to(displacementSprite, {
            duration: 1,
            repeat: -1,
            ease: 'none',
            repeatRefresh: true,
            pixi: {
                x: '+=10',
                y: '+=10',
            }
        })
    }
    
    initPixi();
}

// Product Image Width
let productImage = $('.product-prolog .background .image');
if(productImage.length) {
    productImage.each(function() { $(this).width( $(this).height() ) })
    $(window).resize(function() { productImage.each(function() { $(this).width( $(this).height() ) }) })
}

// Click to Product Features
$('.product-prolog .content .button').click(function() {
    $('html, body').animate({ scrollTop: $('.product-features').offset().top }, 1000);
})

// Service Image Width
let serviceImage = $('.service-prolog .image');
if(serviceImage.length) {
    serviceImage.each(function() { $(this).width( $(this).height() ) })
    $(window).resize(function() { serviceImage.each(function() { $(this).width( $(this).height() ) }) })
}

// Service Carousel Image Width
let serviceCarouselImage = $('.services-carousel-wrapper .container .services-image-carousel .swiper-wrapper .swiper-slide .image');
if(serviceCarouselImage.length) {
    serviceCarouselImage.each(function() { $(this).width( $(this).height() ) })
    $(window).resize(function() { serviceCarouselImage.each(function() { $(this).width( $(this).height() ) }) })
}

// About Image Width
let aboutImage = $('.about-whatwedo .container .detail .image');
if(aboutImage.length) {
    aboutImage.each(function() { $(this).width( $(this).height() ) })
    $(window).resize(function() { aboutImage.each(function() { $(this).width( $(this).height() ) }) })
}

let serviceProcessRepeat = $('.repeat-service-process');
serviceProcessRepeat.click(function() {
    $('html, body').animate({ scrollTop: $('.service-prolog').height() + parseInt($('.service-prolog').css('margin-bottom').replace('px!important;', '')) }, 1000);
})

let scrollDown = $('.scroll-down');
scrollDown.click(function() {
    $('html, body').animate({ scrollTop: $(this).closest('section').next().offset().top }, 1000);
})

let serviceDetailTexture = $('.service-detail .texture');
if( serviceDetailTexture.length ) {
    let serviceDetailPIXI;

    function initPixi() {
        serviceDetailPIXI = new PIXI.Application({backgroundAlpha: 0, width: serviceDetailTexture.innerWidth(), height: serviceDetailTexture.innerHeight()});
        serviceDetailTexture.append(serviceDetailPIXI.view);
    
        let image = new PIXI.Sprite.from( serviceDetailTexture.find('img').attr('src') );
        image.width = serviceDetailTexture.innerWidth();
        image.height = serviceDetailTexture.innerHeight();
        serviceDetailPIXI.stage.addChild(image);
    
        let displacementSprite, displacementFilter;
        displacementSprite = new PIXI.Sprite.from('../assets/image/static/cloud.jpg');
        displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
        displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
        serviceDetailPIXI.stage.addChild(displacementSprite);
        serviceDetailPIXI.stage.filters = [displacementFilter];
    
        displacementSprite.scale.x = 0.75;
        displacementSprite.scale.y = 1;

        gsap.to(displacementSprite, {
            duration: 1,
            repeat: -1,
            ease: 'none',
            repeatRefresh: true,
            pixi: {
                x: '+=10',
                y: '+=10',
            }
        })
    }
    
    initPixi();
}

let projectTrigger = $('.about-whatwedo .container .detail .context .project-trigger');
projectTrigger.click(function() {
    $('html, body').animate({ scrollTop: $('.contact-field').offset().top }, 1000);
})

let quoteTexture = $('.quote-field .texture');
if( quoteTexture.length ) {
    let quotePIXI;

    function initPixi() {
        quotePIXI = new PIXI.Application({backgroundAlpha: 0, width: quoteTexture.innerWidth(), height: quoteTexture.innerHeight()});
        quoteTexture.append(quotePIXI.view);
    
        let image = new PIXI.Sprite.from( quoteTexture.find('img').attr('src') );
        image.width = quoteTexture.innerWidth();
        image.height = quoteTexture.innerHeight();
        quotePIXI.stage.addChild(image);
    
        let displacementSprite, displacementFilter;
        displacementSprite = new PIXI.Sprite.from('../assets/image/static/cloud.jpg');
        displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
        displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
        quotePIXI.stage.addChild(displacementSprite);
        quotePIXI.stage.filters = [displacementFilter];
    
        displacementSprite.scale.x = 0.75;
        displacementSprite.scale.y = 1;

        gsap.to(displacementSprite, {
            duration: 1,
            repeat: -1,
            ease: 'none',
            repeatRefresh: true,
            pixi: {
                x: '+=10',
                y: '+=10',
            }
        })
    }
    
    initPixi();
}

// Index Image Width
let indexAboutImage = $('.index-about .container .image');
if(indexAboutImage.length) {
    indexAboutImage.each(function() { $(this).width( $(this).height() ) })
    $(window).resize(function() { indexAboutImage.each(function() { $(this).width( $(this).height() ) }) })
}

// Index Hero Image Width
let indexHeroImage = $('.hero .content .image-area .hero-image-carousel .swiper-wrapper .swiper-slide .image');
if(indexHeroImage.length) {
    indexHeroImage.each(function() { $(this).width( $(this).height() ) })
    $(window).resize(function() { indexHeroImage.each(function() { $(this).width( $(this).height() ) }) })
}

// Index Product Wrapper Width
let indexProductWrapper = $('.index-products .wrapper');
if(indexProductWrapper.length) {
    indexProductWrapper.each(function() { $(this).width( $(this).height() ) })
    $(window).resize(function() { indexProductWrapper.each(function() { $(this).width( $(this).height() ) }) })
}

// Index Product Wrapper Width
let indexProductImages = $('.index-products .container .wrapper .content .images .image');
if(indexProductImages.length) {
    indexProductImages.each(function() { $(this).height( $(this).width() ) })
    $(window).resize(function() { indexProductImages.each(function() { $(this).height( $(this).width() ) }) })
}

$('.index-products .container .wrapper .content .context .reveal-products .product-list').stop(true, true).slideUp(300);
$('.index-products .container .wrapper .content .context .reveal-products .button').click(function() {
    let parentReveal = $(this).closest('.reveal-products');
    if(!parentReveal.hasClass('active')) {
        parentReveal.addClass('active');
        $('.index-products .container .wrapper .content .context .reveal-products .product-list').stop(true, true).slideDown(300);
    }
    else {
        parentReveal.removeClass('active');
        $('.index-products .container .wrapper .content .context .reveal-products .product-list').stop(true, true).slideUp(300);
    }
})