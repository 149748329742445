// GSAP -------------------------------------------------------------
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger'
import DrawSVGPlugin from 'gsap/DrawSVGPlugin'
import MotionPathPlugin from 'gsap/MotionPathPlugin'
import SplitText from 'gsap/SplitText'
import CSSRulePlugin from 'gsap/CSSRulePlugin'
import Draggable from 'gsap/Draggable'
import PixiPlugin from 'gsap/PixiPlugin'
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, DrawSVGPlugin, SplitText, MotionPathPlugin, CSSRulePlugin, Draggable, PixiPlugin);
window.ScrollTrigger = ScrollTrigger;
window.SplitText = SplitText;
window.CSSRulePlugin = CSSRulePlugin;
window.Draggable = Draggable;
window.gsap = gsap;

gsap.utils.toArray(".main-menu li a").forEach(element => {
    document.fonts.ready.then(() => {
        let actual = new SplitText(element.querySelector(".actual"), { type: "chars, words, lines", charsClass: 'split-chars', wordsClass: 'split-words', linesClass: 'split-lines' });
        let placeholder = new SplitText(element.querySelector(".placeholder"), { type: "chars, words, lines", charsClass: 'split-chars', wordsClass: 'split-words', linesClass: 'split-lines' });
        
        let menuTL = gsap.timeline({ paused: true });
        
        menuTL.to(placeholder.chars, { duration: 0.25, transform: 'perspective(1000px) rotateY(60deg)', opacity: 0, stagger: 0.015 })
        .to(actual.chars, { delay: 0.05, duration: 0.5, transform: 'perspective(1000px) rotateY(0deg)', opacity: 1, stagger: 0.015 }, 0);
        
        element.addEventListener("mouseenter", () => menuTL.play());
        element.addEventListener("mouseleave", () => menuTL.reverse());
    });
});

gsap.utils.toArray(".text-hover-effect").forEach(element => {
    document.fonts.ready.then(() => {
        let placeholder = new SplitText(element.querySelector(".placeholder"), { type: "chars, words, lines", charsClass: 'split-chars', wordsClass: 'split-words', linesClass: 'split-lines' });
        let actual = new SplitText(element.querySelector(".actual"), { type: "chars, words, lines", charsClass: 'split-chars', wordsClass: 'split-words', linesClass: 'split-lines' });
        
        let menuTL = gsap.timeline({ paused: true });
        
        menuTL.to(placeholder.chars, { duration: 0.25, transform: 'perspective(1000px) rotateY(60deg)', opacity: 0, stagger: 0.015 })
        .to(actual.chars, { delay: 0.05, duration: 0.5, transform: 'perspective(1000px) rotateY(0deg)', opacity: 1, stagger: 0.015 }, 0);
        
        element.addEventListener("mouseenter", () => menuTL.play());
        element.addEventListener("mouseleave", () => menuTL.reverse());
    });
});

// Phone Hover Effect
let phoneIcon = $('.phone-hover-effect');
if( phoneIcon.length ) {
    let phoneIconTL = gsap.timeline({ paused: true, onComplete: () => { phoneIconTL.restart(); phoneIconTL.pause(); } });
    let phoneIconTL2 = gsap.timeline({ paused: true, onComplete: () => { phoneIconTL2.restart(); phoneIconTL2.pause(); } });
    phoneIconTL.to(phoneIcon.find('.wave-2'), { duration: 0.3,  scale: 1.25, x: 6, y: -3, opacity: 0, })
    phoneIconTL.to(phoneIcon.find('.wave-1'), { duration: 0.3, scale: 1.25, x: 6, y: -3, opacity: 0, })
    phoneIconTL.to(phoneIcon.find('.wave-2'), { duration: 0,  scale: 1, x: -6, y: 3, opacity: 0, }, 'same')
    phoneIconTL.to(phoneIcon.find('.wave-1'), { duration: 0, scale: 1, x: -6, y: 3, opacity: 0, }, 'same')
    phoneIconTL.to(phoneIcon.find('.wave-2'), { duration: 0.3, scale: 1, x: 0, y: 0, opacity: 1, })
    phoneIconTL.to(phoneIcon.find('.wave-1'), { duration: 0.3, scale: 1, x: 0, y: 0, opacity: 1, })

    phoneIconTL2.to(phoneIcon.find('.handset'), { duration: 0.1, rotate: -10, opacity: 1, })
    phoneIconTL2.to(phoneIcon.find('.handset'), { duration: 0.1, rotate: 10, opacity: 1, })
    phoneIconTL2.to(phoneIcon.find('.handset'), { duration: 0.1, rotate: -10, opacity: 1, })
    phoneIconTL2.to(phoneIcon.find('.handset'), { duration: 0.1, rotate: 10, opacity: 1, })
    phoneIconTL2.to(phoneIcon.find('.handset'), { duration: 0.1, rotate: -10, opacity: 1, })
    phoneIconTL2.to(phoneIcon.find('.handset'), { duration: 0.1, rotate: 10, opacity: 1, })
    phoneIconTL2.to(phoneIcon.find('.handset'), { duration: 0.1, rotate: -10, opacity: 1, })
    phoneIconTL2.to(phoneIcon.find('.handset'), { duration: 0.1, rotate: 10, opacity: 1, })
    phoneIconTL2.to(phoneIcon.find('.handset'), { duration: 0.1, rotate: 0, opacity: 1, })
    phoneIcon.mouseenter(function() { phoneIconTL.play(); phoneIconTL2.play(); })
}   

let whatsappIcon = $('.whatsapp-hover-effect');
if( whatsappIcon.length ) {
    let whatsappIconTL = gsap.timeline({ paused: true, onComplete: () => { whatsappIconTL.restart(); whatsappIconTL.pause(); } });
    whatsappIconTL.to(whatsappIcon.find('.handset'), { duration: 0.1, rotate: -10, opacity: 1, })
    whatsappIconTL.to(whatsappIcon.find('.handset'), { duration: 0.1, rotate: 10, opacity: 1, })
    whatsappIconTL.to(whatsappIcon.find('.handset'), { duration: 0.1, rotate: -10, opacity: 1, })
    whatsappIconTL.to(whatsappIcon.find('.handset'), { duration: 0.1, rotate: 10, opacity: 1, })
    whatsappIconTL.to(whatsappIcon.find('.handset'), { duration: 0.1, rotate: -10, opacity: 1, })
    whatsappIconTL.to(whatsappIcon.find('.handset'), { duration: 0.1, rotate: 10, opacity: 1, })
    whatsappIconTL.to(whatsappIcon.find('.handset'), { duration: 0.1, rotate: -10, opacity: 1, })
    whatsappIconTL.to(whatsappIcon.find('.handset'), { duration: 0.1, rotate: 10, opacity: 1, })
    whatsappIconTL.to(whatsappIcon.find('.handset'), { duration: 0.1, rotate: 0, opacity: 1, })
    whatsappIcon.mouseenter(function() { whatsappIconTL.play(); })
}

let envelopeIcon = $('.envelope-hover-effect');
if( envelopeIcon.length ) {
    let envelopeIconTL = gsap.timeline({ paused: true, onComplete: () => { envelopeIconTL.restart(); envelopeIconTL.pause(); } });
    envelopeIconTL.to(envelopeIcon.find('.cap'), { duration: 0.3, scaleY: -1, y: '-150%', }, 'same')
    envelopeIconTL.to(envelopeIcon.find('svg'), { duration: 0.45, rotate: -15, }, 'same')
    envelopeIconTL.to(envelopeIcon.find('.cap'), { delay: 0.3, duration: 0.3, scaleY: 1, y: 0, }, 'same2')
    envelopeIconTL.to(envelopeIcon.find('svg'), { delay: 0.3, duration: 0.45, rotate: 0, }, 'same2')

    envelopeIcon.mouseenter(function() { envelopeIconTL.play(); })
}

let clockIcon = $('.clock-hover-effect');
if( clockIcon.length ) {
    let clockIconTL = gsap.timeline({ paused: true, onComplete: () => { clockIconTL.restart(); clockIconTL.pause(); } });
    clockIconTL.to(clockIcon.find('.pin'), { duration: 0.6, rotate: 360, scale: 0.5, transformOrigin: '0% 50%' })
    clockIconTL.to(clockIcon.find('.pin'), { duration: 0.6, rotate: 0, scale: 1, transformOrigin: '0% 50%' })

    clockIcon.mouseenter(function() { clockIconTL.play(); })
}

let arrowRightTop = $('.arrow-right-top-hover-effect');
if( arrowRightTop.length ) {
    let arrowRightTopTL = gsap.timeline({ paused: true, onComplete: () => { arrowRightTopTL.restart(); arrowRightTopTL.pause(); } });
    arrowRightTopTL.to(arrowRightTop.find('svg'), { duration: 0.45, x: '200%', y: '-200%', opacity: 0, }, 'same')
    arrowRightTopTL.to(arrowRightTop.find('.line'), { duration: 0.6, scale: 0, transformOrigin: '50% 50%' }, 'same')
    arrowRightTopTL.to(arrowRightTop.find('svg'), { duration: 0, x: '-200%', y: '200%', opacity: 0, })
    arrowRightTopTL.to(arrowRightTop.find('svg'), { duration: 0.45, x: '0%', y: '0%', opacity: 1, }, 'same2')
    arrowRightTopTL.to(arrowRightTop.find('.line'), { duration: 0.6, scale: 1, transformOrigin: '50% 50%' }, 'same2')
    arrowRightTop.mouseenter(function() { arrowRightTopTL.play(); })
}

let arrowRightBottom = $('.arrow-right-bottom-hover-effect');
if( arrowRightBottom.length ) {
    let arrowRightBottomTL = gsap.timeline({ paused: true, onComplete: () => { arrowRightBottomTL.restart(); arrowRightBottomTL.pause(); } });
    arrowRightBottomTL.to(arrowRightBottom.find('svg'), { duration: 0.45, x: '200%', y: '200%', opacity: 0, }, 'same')
    arrowRightBottomTL.to(arrowRightBottom.find('.line'), { duration: 0.6, scale: 0, transformOrigin: '50% 50%' }, 'same')
    arrowRightBottomTL.to(arrowRightBottom.find('svg'), { duration: 0, x: '-200%', y: '-200%', opacity: 0, })
    arrowRightBottomTL.to(arrowRightBottom.find('svg'), { duration: 0.45, x: '0%', y: '0%', opacity: 1, }, 'same2')
    arrowRightBottomTL.to(arrowRightBottom.find('.line'), { duration: 0.6, scale: 1, transformOrigin: '50% 50%' }, 'same2')
    arrowRightBottom.mouseenter(function() { arrowRightBottomTL.play(); })
}

let cameraIcon = $('.camera-hover-effect');
if( cameraIcon.length ) {
    let cameraIconTL = gsap.timeline({ paused: true, onComplete: () => { cameraIconTL.restart(); cameraIconTL.pause(); } });
    cameraIconTL.to(cameraIcon.find('.circle'), { duration: 0.45, transformOrigin: '50% 50%', scale: 0.5 })
    cameraIconTL.to(cameraIcon.find('.line'), { duration: 0.2, transformOrigin: '0% 50%', scaleX: 0.5 })
    cameraIconTL.to(cameraIcon.find('.line'), { duration: 0.2, transformOrigin: '50% 50%', scaleX: 1 })
    cameraIconTL.to(cameraIcon.find('.line'), { duration: 0.2, transformOrigin: '100% 50%', scaleX: 0.5 })
    cameraIconTL.to(cameraIcon.find('.circle'), { duration: 0.45, transformOrigin: '50% 50%', scale: 1 })
    cameraIconTL.to(cameraIcon.find('.line'), { duration: 0.2, transformOrigin: '50% 50%', scaleX: 1 })
    cameraIcon.mouseenter(function() { cameraIconTL.play(); })
}

let bullseyeIcon = $('.bullseye-hover-effect');
if( bullseyeIcon.length ) {
    let bullseyeIconTL = gsap.timeline({ paused: true, onComplete: () => { bullseyeIconTL.restart(); bullseyeIconTL.pause(); } });
    bullseyeIconTL.to(bullseyeIcon.find('.frame'), { duration: 0.45, transformOrigin: '50% 50%', scale: 1.25, rotate: 180 })
    bullseyeIconTL.to(bullseyeIcon.find('.frame'), { duration: 0.45, transformOrigin: '50% 50%', scale: 1, rotate: 0 })
    bullseyeIcon.mouseenter(function() { bullseyeIconTL.play(); })
}

let reverseIcon = $('.reverse-hover-effect');
if( reverseIcon.length ) {
    let reverseIconTL = gsap.timeline({ paused: true, onComplete: () => { reverseIconTL.restart(); reverseIconTL.pause(); } });
    reverseIconTL.to(reverseIcon.find('svg'), { duration: 0.45, transformOrigin: '50% 55%', rotate: 360 })
    reverseIcon.mouseenter(function() { reverseIconTL.play(); })
}

let princibleIcon = $('.princible-hover-effect');
if( princibleIcon.length ) {
    let princibleIconTL = gsap.timeline({ paused: true, onComplete: () => { princibleIconTL.restart(); princibleIconTL.pause(); } });
    princibleIconTL.to(princibleIcon.find('.frame'), { duration: 0.45, transformOrigin: '50% 50%', rotate: 90 }, 'same-1')
    princibleIconTL.to(princibleIcon.find('.check'), { duration: 0.45, transformOrigin: '50% 50%', scale: 1.2, }, 'same-1')
    princibleIconTL.to(princibleIcon.find('.check'), { duration: 0.45, transformOrigin: '50% 50%', scale: 1, }, 'same-2')
    princibleIconTL.to(princibleIcon.find('.frame'), { duration: 0.45, transformOrigin: '50% 50%', rotate: 0 }, 'same-2')
    princibleIcon.mouseenter(function() { princibleIconTL.play(); })
}

let visionIcon = $('.vision-hover-effect');
if( visionIcon.length ) {
    let visionIconTL = gsap.timeline({ paused: true, onComplete: () => { visionIconTL.restart(); visionIconTL.pause(); } });
    visionIconTL.to(visionIcon.find('svg'), { duration: 0.45, transformOrigin: '50% 50%', rotate: 90 }, 'same-1')
    visionIconTL.to(visionIcon.find('.circles'), { duration: 0.45, transformOrigin: '50% 50%', scale: 1.2, }, 'same-1')
    visionIconTL.to(visionIcon.find('.circles'), { duration: 0.45, transformOrigin: '50% 50%', scale: 1 }, 'same-2')
    visionIconTL.to(visionIcon.find('svg'), { duration: 0.45, transformOrigin: '50% 50%', rotate: 0 }, 'same-2')
    visionIcon.mouseenter(function() { visionIconTL.play(); })
}

let serviceShadow = $('.service-prolog .image .shadow');
if( serviceShadow.length ) {
    gsap.to('.service-prolog .image .shadow .circle', {
        repeat: -1,
        duration: 5,
        ease: 'none',
        motionPath: { path: ".service-prolog .image .shadow .line", align: ".service-prolog .image .shadow .line", alignOrigin: [0.5, 0.5], start: 0, end: 1, },
    });
}

// Scroll Down Wave
let scrollDownWave = $('.scroll-down .wave');
if( scrollDownWave.length ){
    let scrollDownTL = gsap.timeline({ repeat: -1, ease: 'none' });
    scrollDownTL.from(scrollDownWave.find('span:nth-child(1)'), { duration: 1, opacity: 0, }, 'same')
    scrollDownTL.from(scrollDownWave.find('span:nth-child(2)'), { duration: 1, opacity: 0, }, 'same')
    scrollDownTL.from(scrollDownWave.find('span:nth-child(3)'), { duration: 1, opacity: 0, }, 'same')
    scrollDownTL.to(scrollDownWave.find('span:nth-child(1)'), { duration: 2, scale: 1.9, opacity: 0, })
    scrollDownTL.to(scrollDownWave.find('span:nth-child(2)'), { duration: 2, scale: 1.7, opacity: 0, }, '-=1.5')
    scrollDownTL.to(scrollDownWave.find('span:nth-child(3)'), { duration: 2, scale: 1.5, opacity: 0, }, '-=1.5')
}

// Service Process
let serviceProcess = $('.service-process');
if( serviceProcess.length ) {
    gsap.set('.service-process .wrapper .background .stroke', { drawSVG: '0%', })

    let serviceProcessTL = gsap.timeline({
        scrollTrigger: {
            trigger: serviceProcess,
            start: 'bottom bottom',
            end: 'bottom+=400% bottom',
            scrub: 1.5,
            anticipatePin: 1,
            pin: true,
        }
    });

    serviceProcessTL.to('.service-process .wrapper .content-begin', { y: -30, opacity: 0, pointerEvents: 'none', })
    serviceProcessTL.to('.service-process .wrapper .background .stroke', { drawSVG: '25%', }, 'same-1')
    serviceProcessTL.from('.service-process .wrapper .corners .item:nth-child(1)', { scale: 0, }, 'same-1')
    serviceProcessTL.from('.service-process .wrapper .content .item:nth-child(1)', { opacity: 0, y: 30 }, 'same-1')

    serviceProcessTL.to('.service-process .wrapper .content .item:nth-child(1)', { opacity: 0, y: -30 })
    serviceProcessTL.to('.service-process .wrapper .background .stroke', { delay: 0.3, drawSVG: '50%', }, 'same-2')
    serviceProcessTL.from('.service-process .wrapper .corners .item:nth-child(2)', { scale: 0, }, 'same-2')
    serviceProcessTL.from('.service-process .wrapper .content .item:nth-child(2)', { opacity: 0, y: 30 }, 'same-2')

    serviceProcessTL.to('.service-process .wrapper .content .item:nth-child(2)', { opacity: 0, y: -30 })
    serviceProcessTL.to('.service-process .wrapper .background .stroke', { delay: 0.3, drawSVG: '75%', }, 'same-3')
    serviceProcessTL.from('.service-process .wrapper .corners .item:nth-child(3)', { scale: 0, }, 'same-3')
    serviceProcessTL.from('.service-process .wrapper .content .item:nth-child(3)', { opacity: 0, y: 30 }, 'same-3')

    serviceProcessTL.to('.service-process .wrapper .content .item:nth-child(3)', { opacity: 0, y: -30 })
    serviceProcessTL.to('.service-process .wrapper .background .stroke', { delay: 0.3, drawSVG: '100%', }, 'same-4')
    serviceProcessTL.from('.service-process .wrapper .corners .item:nth-child(4)', { scale: 0, }, 'same-4')
    serviceProcessTL.from('.service-process .wrapper .content .item:nth-child(4)', { opacity: 0, y: 30 }, 'same-4')

    serviceProcessTL.to('.service-process .wrapper .content .item:nth-child(4)', { opacity: 0, y: -30 }, 'same-5')
    serviceProcessTL.from('.service-process .wrapper .content-end', { delay: 0.3, y: 30, opacity: 0, }, 'same-5')
}

let serviceCarouselShadow = $('.services-carousel-wrapper .container .services-image-carousel .swiper-wrapper .swiper-slide .image .shadow');
if( serviceCarouselShadow.length ) {
    serviceCarouselShadow.each(function() {
        gsap.to($(this).find('.circle'), {
            repeat: -1,
            duration: 5,
            ease: 'none',
            motionPath: { path: $(this).find('.line')[0], align: $(this).find('.line')[0], alignOrigin: [0.5, 0.5], start: 0, end: 1, },
        });
    })
    
}

let indexProductField = $('.index-products');
if(indexProductField.length) {
    let indexProductFieldTL = gsap.timeline({
        scrollTrigger: {
            trigger: indexProductField,
            start: 'bottom bottom',
            end: 'bottom+=300% bottom',
            scrub: 1.5,
            anticipatePin: 1,
            pin: true,
        }
    });

    indexProductFieldTL.to('.index-products .container .wrapper .content .images', { y: '-100%', })
    indexProductFieldTL.from('.index-products .container .wrapper .content .context .heading', { y: '-50%', opacity: 0, }, 0.25)
    indexProductFieldTL.from('.index-products .container .wrapper .content .context .reveal-products', { y: '50%', opacity: 0, }, 0.25)
}

// Product Detail Timeline
let productDetailItem = $('.product-features .list .item');
let productDetailSplit = $('.product-features .list .split');
let productDetailTL = gsap.timeline();
if( productDetailItem.length && $(window).width() > mobileLimit) {
    productDetailItem.each(function() {
        productDetailTL.from($(this), {
            scrollTrigger: {
                trigger: $(this),
                start: 'top-=200% bottom',
                end: 'bottom+=200% bottom',
                scrub: 1.5,
            },
            y: 45,
            scale: 0.5,
            opacity: 0,
        })
    })

    productDetailSplit.each(function() {
        productDetailTL.from($(this), {
            scrollTrigger: {
                trigger: $(this),
                start: 'bottom+=500% bottom',
                end: 'bottom+=200% bottom',
                delay: 0.6,
                scrub: 2,
            },
            scaleX: 0,
        })
    })
}

// Product Images
let productImage = $('.product-prolog .background .image');
if(productImage.length && $(window).width() > mobileLimit) {
    gsap.from(productImage, {
        duration: 1.2,
        x: '25%',
        y: '25%',
        opacity: 0,
        stagger: 0.6,
    })
}