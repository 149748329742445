// ScrollReveal -----------------------------------------------------
import ScrollReveal from 'scrollreveal';
window.ScrollReveal = ScrollReveal;
ScrollReveal({ afterReveal: function(el) { el.removeAttribute('style'); el.removeAttribute('data-sr-id'); } });

// Contact Field
ScrollReveal().reveal('.contact-field .info-field .logo', { distance: '30px', duration: 1000, origin: 'bottom', viewFactor: 0.25, mobile: false }); 
ScrollReveal().reveal('.contact-field .info-field .info-contact .item .heading', { interval: 150, distance: '30px', duration: 1000, origin: 'right', viewFactor: 0.25, mobile: false }); 
ScrollReveal().reveal('.contact-field .info-field .info-contact .item .value', { interval: 150, distance: '30px', duration: 1250, origin: 'right', viewFactor: 0.25, mobile: false }); 
ScrollReveal().reveal('.contact-field .info-field .info-working-hours', { distance: '30px', duration: 1000, origin: 'right', viewFactor: 0.25, mobile: false }); 
ScrollReveal().reveal('.contact-field .info-field .info-address > *', { interval: 150, distance: '30px', duration: 1000, origin: 'right', viewFactor: 0.25, mobile: false }); 
ScrollReveal().reveal('.contact-field .info-field .info-links', { distance: '30px', duration: 1000, origin: 'bottom', viewFactor: 0.25, mobile: false }); 
ScrollReveal().reveal('.contact-field .form-field .form .item', { interval: 150, distance: '30px', duration: 1000, origin: 'bottom', viewFactor: 0.25, mobile: false }); 

// Reference Field
ScrollReveal().reveal('.reference-field .reference-list .item', { interval: 150, distance: '30px', duration: 1000, origin: 'bottom', viewFactor: 0.25, mobile: false }); 

// Princibles Field
ScrollReveal().reveal('.princibles-field .container .item .title', { delay: 300, distance: '30px', duration: 1000, origin: 'right', viewFactor: 0.25, mobile: false }); 
ScrollReveal().reveal('.princibles-field .container .item .expo', { delay: 450, distance: '30px', duration: 1000, origin: 'right', viewFactor: 0.25, mobile: false }); 

// Service Page
ScrollReveal().reveal('.service-detail .container .detail .list .item', { interval: 150, distance: '30px', duration: 1000, origin: 'right', viewFactor: 0.25, mobile: false }); 
ScrollReveal().reveal('.service-detail .container .owner .naming > *', { interval: 150, distance: '30px', duration: 1000, origin: 'right', viewFactor: 0.25, mobile: false });

// About Page
ScrollReveal().reveal('.about-whatwedo .container .detail .context .list > *', { interval: 150, distance: '30px', duration: 1000, origin: 'right', viewFactor: 0, mobile: false }); 
ScrollReveal().reveal('.about-whatwedo .container .detail .context .project-trigger', { distance: '30px', duration: 1000, origin: 'bottom', viewFactor: 0.25, mobile: false }); 
ScrollReveal().reveal('.text-field', { interval: 150, distance: '30px', duration: 1000, origin: 'bottom', viewFactor: 0, mobile: false }); 